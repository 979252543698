
import { defineComponent, ref ,reactive ,toRefs ,onMounted} from 'vue';
import {getTableData ,getSatusApi ,downloadDocumentsZip} from '@/API/taskCenter/packageTask'
interface TableDataType {
    id: string;
    requestDate: string;
    taskDesc: string;
    docAmount: string;
    docSize: string;
    expiredDate: string;
    status: string;
}

export default defineComponent({
    setup() {
        const requestDate = ref<string[]>([]);
        const status = ref<undefined|string>(undefined);
        const statusArray = ref<any[]>([]);

        //分页
        const pagination = reactive({
            total:0,
            currentPage:1,
            pageSize:20
        })

        //表格
        const tableHeight = window.innerHeight - 320;
        const dataSource = ref<TableDataType[]>([
            
        ]);
        const columns = ref([
            {
                title: 'Request Date',
                dataIndex: 'requestDate',
                key: 'requestDate',
            },
            {
                title: 'Task Descirption',
                dataIndex: 'taskDesc',
                key: 'taskDesc',
            },
            {
                title: 'Documents Amount',
                dataIndex: 'docAmount',
                key: 'docAmount',
            },
            {
                title: 'Documents Size',
                dataIndex: 'docSize',
                key: 'docSize',
            },
            {
                title: 'Expired Date',
                dataIndex: 'expiredDate',
                key: 'expiredDate',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'Operation',
                slots: { customRender: 'action' },
                key: 'action',
            },
        ]);

        //Download事件
        const Download = (item: any)=>{
            // console.log('Download')
            downloadDocumentsZip({taskId:item.id}).then((res: any): void => {
                 const downloadElement = document.createElement('a')
                downloadElement.href = res.downloadUrl
                downloadElement.download = res.fileName
                document.body.appendChild(downloadElement)
                downloadElement.click() //点击下载
                document.body.removeChild(downloadElement) //下载完成移除元素
            })
        }

        interface ParamsType{
            startTime: null|string;
            endTime: null|string;
            status: null|string;
            current: number;
            size: number; 
        }

        const params = reactive<ParamsType>({
            startTime:null,
            endTime:null,
            status:null,
            current:0,
            size:20
        })

        const getTableDataByCondition=()=>{    
            getTableData(params).then((res: any): void => {
                // debugger
                dataSource.value = res.content;
                pagination.total = parseInt(res.totalElements)
            })
        }

        const clearParams=()=>{
            requestDate.value = [];
            status.value = undefined;
        }
        
        const searchTable=()=>{
            params.startTime = null;
            params.endTime = null;
            params.status = null;
            if (requestDate.value.length>0){
                params.startTime = requestDate.value[0];
                params.endTime = requestDate.value[1];
            }
            if (status.value !== undefined && status.value !== ""){
                params.status = (status.value as string);
            }
            params.current = 0;
            params.size = pagination.pageSize;

            getTableDataByCondition()
        }
        //更改分页页签
        const pageChange = (page: number)=>{
            pagination.currentPage = page;
            params.current = page-1;
            getTableDataByCondition();
        }
        //改变每页大小
        const sizeChange = (page: number,pageSize: number)=>{
            pagination.pageSize = pageSize;
            pagination.currentPage = 1;
            params.size = pageSize;
            params.current = 0;
            getTableDataByCondition();
        }
        

        onMounted(() => {
            getTableDataByCondition();
            getSatusApi().then((res: any): void => {
                console.log(res)
                statusArray.value = res;
            })
        })
        return {status,statusArray, clearParams,searchTable,requestDate ,...toRefs(pagination),pageChange,sizeChange,tableHeight,dataSource,columns,Download};
    },
});
